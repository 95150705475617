.card {
    margin-block-end: 1.5rem;
    color: $default-text-color;

    .card-header,
    .card-footer {
        background-color: $custom-white !important;
        color: $default-text-color;
    }
}

.card.custom-card {
    border-radius: $default-radius;
    border: 0;
    background-color: $custom-white;
    position: relative;
    margin-block-end: 1.5rem;
    width: 100%;
    box-shadow: 0 3px 9px 0 rgba(169, 184, 200, 0.15);

    .card-header {
        padding: 1rem 1.5rem;
        background-color: transparent !important;
        border-block-end: 1px solid $default-border;
        display: flex;
        align-items: center;
        position: relative;
        flex-wrap: wrap;
        gap: 0.25rem;

        &:first-child {
            border-radius: 0.625rem 0.625rem 0 0;
        }

        .card-title {
            position: relative;
            margin-block-end: 0;
            font-size: 1rem;
            font-weight: 500;
            text-transform: capitalize;

            .subtitle {
                margin-block-end: 0;
                text-transform: initial;
            }
        }
    }

    .card-footer {
        background-color: transparent !important;
        border-block-start: 1px solid $default-border;
        padding: 1rem 1.25rem;
        font-size: $default-font-size;
    }

    .card-body {
        padding: 1.563rem;
        color: $default-text-color;
    }

    .card-link {
        font-size: 0.75rem;
    }

    .card-text {
        font-size: 0.813rem;
    }

    .footer-card-icon {
        width: 60px;
        height: 60px;
    }

    &.overlay-card {
        position: relative;
        overflow: hidden;
        color: rgba(255, 255, 255, 0.9);

        .card-header {
            border-block-end: 1px solid rgba(255, 255, 255, 0.1);
        }

        .card-footer {
            border-block-start: 1px solid rgba(255, 255, 255, 0.1);
        }

        .over-content-bottom {
            top: auto;
        }

        &:before {
            content: "";
            inset-inline-start: 0;
            inset-inline-end: 0;
            inset-block-start: 0;
            inset-block-end: 0;
            background-color: rgba(0, 0, 0, 0.2);
            position: absolute;
        }
    }

    /* Start::Card Background Colors */
    &.card-bg-primary {
        background-color: $primary;
        color: $white;

        .card-header {
            background-color: rgba(255, 255, 255, 0.1) !important;
            color: $white;
            border-block-end: 1px solid rgba(255, 255, 255, 0.2);

            &:before {
                background-color: $white;
            }
        }

        .card-body {
            color: $white;
        }

        .card-footer {
            background-color: rgba(255, 255, 255, 0.1) !important;
            color: $white;
            border-block-start: 1px solid rgba(255, 255, 255, 0.2);
        }
    }

    &.card-bg-secondary {
        background-color: $secondary;
        color: $white;

        .card-header {
            background-color: rgba(255, 255, 255, 0.1) !important;
            color: $white;
            border-block-end: 1px solid rgba(255, 255, 255, 0.2);

            &:before {
                background-color: $white;
            }
        }

        .card-body {
            color: $white;
        }

        .card-footer {
            background-color: rgba(255, 255, 255, 0.1) !important;
            color: $white;
            border-block-start: 1px solid rgba(255, 255, 255, 0.2);
        }
    }

    &.card-bg-warning {
        background-color: $warning;
        color: $white;

        .card-header {
            background-color: rgba(255, 255, 255, 0.1) !important;
            color: $white;
            border-block-end: 1px solid rgba(255, 255, 255, 0.2);

            &:before {
                background-color: $white;
            }
        }

        .card-body {
            color: $white;
        }

        .card-footer {
            background-color: rgba(255, 255, 255, 0.1) !important;
            color: $white;
            border-block-start: 1px solid rgba(255, 255, 255, 0.2);
        }
    }

    &.card-bg-info {
        background-color: $info;
        color: $white;

        .card-header {
            background-color: rgba(255, 255, 255, 0.1) !important;
            color: $white;
            border-block-end: 1px solid rgba(255, 255, 255, 0.2);

            &:before {
                background-color: $white;
            }
        }

        .card-body {
            color: $white;
        }

        .card-footer {
            background-color: rgba(255, 255, 255, 0.1) !important;
            color: $white;
            border-block-start: 1px solid rgba(255, 255, 255, 0.2);
        }
    }

    &.card-bg-success {
        background-color: $success;
        color: $white;

        .card-header {
            background-color: rgba(255, 255, 255, 0.1) !important;
            color: $white;
            border-block-end: 1px solid rgba(255, 255, 255, 0.2);

            &:before {
                background-color: $white;
            }
        }

        .card-body {
            color: $white;
        }

        .card-footer {
            background-color: rgba(255, 255, 255, 0.1) !important;
            color: $white;
            border-block-start: 1px solid rgba(255, 255, 255, 0.2);
        }
    }

    &.card-bg-danger {
        background-color: $danger;
        color: $white;

        .card-header {
            background-color: rgba(255, 255, 255, 0.1) !important;
            color: $white;
            border-block-end: 1px solid rgba(255, 255, 255, 0.2);

            &:before {
                background-color: $white;
            }
        }

        .card-body {
            color: $white;
        }

        .card-footer {
            background-color: rgba(255, 255, 255, 0.1) !important;
            color: $white;
            border-block-start: 1px solid rgba(255, 255, 255, 0.2);
        }
    }

    &.card-bg-pink {
        background-color: $pink;
        color: $white;

        .card-header {
            background-color: rgba(255, 255, 255, 0.1) !important;
            color: $white;
            border-block-end: 1px solid rgba(255, 255, 255, 0.2);

            &:before {
                background-color: $white;
            }
        }

        .card-body {
            color: $white;
        }

        .card-footer {
            background-color: rgba(255, 255, 255, 0.1) !important;
            color: $white;
            border-block-start: 1px solid rgba(255, 255, 255, 0.2);
        }
    }

    &.card-bg-light {
        background-color: $light;
        color: $default-text-color;

        .card-header {
            background-color: rgba(0, 0, 0, 0.05) !important;
            color: $default-text-color;
            border-block-end: $default-border;
        }

        .card-body {
            color: $default-text-color;
        }

        .card-footer {
            background-color: rgba(0, 0, 0, 0.05) !important;
            color: $default-text-color;
            border-block-start: $default-border;
        }
    }

    &.card-bg-dark {
        background-color: $dark;
        color: $custom-white;

        .card-header {
            background-color: rgba(255, 255, 255, 0.1) !important;
            color: $white;
            border-block-end: 1px solid rgba(255, 255, 255, 0.2);

            &:before {
                background-color: $custom-white;
            }
        }

        .card-body {
            color: $white;
        }

        .card-footer {
            background-color: rgba(255, 255, 255, 0.1) !important;
            color: $white;
            border-block-start: 1px solid rgba(255, 255, 255, 0.2);
        }
    }

    /* End::Card Background Colors */

    /* Start:: Cards With Links */
    /* Start:: Cards With Links */
    .card-anchor {
        position: absolute;
        top: 0;
        inset-inline-end: 0;
        bottom: 0;
        inset-inline-start: 0;
        z-index: 1;
        pointer-events: auto;
        content: "";
    }

    /* End:: Cards With Links */

    /* Start:: Card With Collapse */
    .collapse-close {
        display: none;
    }

    &.card .collapsed {
        .collapse-close {
            display: block;
        }

        .collapse-open {
            display: none;
        }
    }

    /* End:: Card With Links */

    /* Start::Card With FullScreen */
    &.card-fullscreen {
        position: fixed;
        top: 0;
        inset-inline-start: 0;
        inset-inline-start: 0;
        bottom: 0;
        z-index: 9999;
        margin: 0;
    }

    /* End::Card With FullScreen */
}

@media (max-width: 575px) {
    .overlay-card .card-text {
        font-size: .613rem !important;
    }
}