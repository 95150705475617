/* Start:Choices JS */
.choices__inner {
  padding: 0.5rem 0.85rem !important;
  background-color: $form-control-bg !important;
  border-radius: 0.35rem !important;
  font-size: $default-font-size !important;
  border: 1px solid $input-border !important;
  min-height: auto !important;
  line-height: 1.7;
}

.choices__input {
  padding: 0 !important;
}

.choices__list--single {
  padding-block: 0 !important;
  padding-inline-start: 0;
  padding-inline-end: 1rem !important;
}

.choices[data-type*=select-one]::after {
  inset-inline-end: .7188rem !important;
}

.choices[data-type*="select-one"]::after {
  color: $text-muted !important;
}

.choices[data-type*="select-one"] .choices__input {
  padding: 0.625rem !important;
}
.choices__list--dropdown { 
  display: none;
}
.choices__list--dropdown.is-active { 
  display: block;
}
.choices__list--dropdown,
.choices__list[aria-expanded] {
  background-color: $custom-white !important;
  border: 1px solid $default-border !important;
  z-index: 1 !important;
  border-radius: 0 0 $default-radius $default-radius !important;
}

.choices[data-type*="select-one"] .choices__input {
  border-block-end: 1px solid $default-border !important;
  background-color: $custom-white !important;
  color: $default-text-color;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted,
.choices__list[aria-expanded] .choices__item--selectable.is-highlighted {
  background-color: $custom-white !important;
}

.choices__list--dropdown .choices__list {
  max-height: 250px !important;
}

.choices[data-type*="select-one"]::after {
  border-color: $text-muted transparent transparent !important;
}

.choices__input {
  background-color: transparent !important;
  color: $custom-black;
}

.choices__list--multiple .choices__item {
  background-color: $primary !important;
  border: 1px solid $primary !important;
}

.choices[data-type*="select-multiple"] .choices__button,
.choices[data-type*="text"] .choices__button {
  border-inline-start: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.choices__list--multiple .choices__item {
  margin-block-end: 0 !important;
}

.choices__list--single .choices__item {
  color: $default-text-color !important;
}

.choices__input {
  margin-block-end: 0 !important;
}

.choices__list--multiple .choices__item {
  border-radius: 0.25rem !important;
  padding: 0.025rem 0.625rem !important;
}

.form-control-select-sm .choices__inner {
  padding: 0.275rem !important;
}

.choices[data-type*="select-one"].is-open::after {
  margin-top: -0.156rem !important;
}

.input-group {
  .choices__inner {
    border-radius: 0 $default-radius $default-radius 0 !important;
  }
}

.choices__heading {
  border-bottom: 1px solid $default-border !important;
  color: $text-muted !important;
}

[dir="rtl"] {

  .choices[data-type*=select-one]::after {
    left: .7188rem;
    right: inherit;
  }

  .choices[data-type*=select-one] .choices__button {
    left: 0;
    right: inherit;
    margin-left: 1.5625rem;
    margin-right: inherit;
  }

  &[data-theme-mode="dark"] {
    .choices[data-type*=select-one] .choices__button {
      filter: invert(1);
    }
  }

  .choices[data-type*=select-multiple] .choices__button,
  .choices[data-type*=text] .choices__button {
    margin: 0 8px 0 -4px;
    padding-right: 1rem;
    padding-left: inherit;
    border-right: 0px solid #008fa1;
    border-left: 0;
  }
}

.choices__list--dropdown,
.choices__list[aria-expanded] {
  box-shadow: rgba(149, 157, 165, 0.2) 0 0.5rem 1.5rem !important;
}
[data-theme-mode = "dark"] {
  .choices[data-type*=select-one] .choices__button {
    filter: invert(1);
  }
}
/* End:Choices JS */

/* Start:Apex Charts */
#marketCap {
  .apexcharts-canvas line {
    stroke: $default-border;
  }
}

.apexcharts-tooltip {
  box-shadow: none !important;
}

.apexcharts-tooltip-marker {
  margin-inline-end: .625rem;
}

.apexcharts-tooltip.apexcharts-theme-light {
  border: 1px solid $default-border !important;
  background: $custom-white !important;
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: $custom-white !important;
  border-block-end: 1px solid $default-border !important;
}

.apexcharts-grid,
.apexcharts-xaxis,
.apexcharts-grid-borders {
  line {
    stroke: $default-border;
  }
}

.apexcharts-radialbar-track.apexcharts-track {
  path {
    stroke: $light;
  }
}

.apexcharts-menu {
  background: $custom-white !important;
  border: 1px solid $default-border !important;
  border-radius: $default-radius !important;
}

.apexcharts-theme-light .apexcharts-menu-item:hover {
  background: $light !important;
}

.apexcharts-inner.apexcharts-graphical {
  line.apexcharts-xaxis-tick {
    stroke: transparent !important;
  }
}

#column-rotated-labels {
  .apexcharts-xaxis-texts-g {
    transform: translateY(2.5rem);
  }
}

#chart-year,
#chart-quarter {
  background: $custom-white !important;
  border: 1px solid $default-border !important;
}

.apexcharts-bar-series.apexcharts-plot-series {
  .apexcharts-series {
    .apexcharts-bar-area {
      stroke: $default-border !important;
    }
  }
}

.apexcharts-treemap {
  .apexcharts-series.apexcharts-treemap-series {
    rect {
      stroke: $custom-white !important;
    }
  }
}

.apexcharts-series.apexcharts-pie-series {
  .apexcharts-pie-area {
    stroke: $custom-white !important;
  }
}

.apexcharts-datalabels-group {
  .apexcharts-text.apexcharts-datalabel-value {
    fill: $default-text-color;
  }
}

.apexcharts-radialbar-hollow {
  fill: $custom-white;
}

.apexcharts-radar-series.apexcharts-plot-series {

  polygon,
  line {
    stroke: $default-border;
  }
}

.apexcharts-pie {

  line,
  circle {
    stroke: $default-border;
  }

  text {
    fill: $default-text-color;
  }
}

.apexcharts-canvas .apexcharts-toolbar {
  z-index: 1;
}

.apexcharts-subtitle-text {
  fill: $text-muted;
}

.apexcharts-pie {
  .apexcharts-datalabels rect {
    fill: transparent;
  }

  text {
    fill: $white;
  }
}

[dir="rtl"] {
  .apexcharts-canvas .apexcharts-yaxis {
    .apexcharts-yaxis-texts-g {
      transform: translate(-1.75rem, 0);
    }
  }
  .apexcharts-menu {
    left: .625rem;
    right: auto;
  }
}

.apexcharts-boxPlot-area {
  stroke: $default-text-color !important;
}

/* End:Apex Charts */

/* Start:Full Calendar */
.fc-theme-standard .fc-scrollgrid.fc-scrollgrid-liquid {
  border: 0px !important;
  border-top: 1px solid $default-border !important;
}

.fc-daygrid-block-event .fc-event-time,
.fc-daygrid-block-event .fc-event-title {
  padding: 0 0.25rem !important;
}

.fc .fc-button-primary {
  background-color: $primary !important;
  border-color: $primary !important;
}

.fc .fc-non-business {
  background: $custom-white !important;
}

.fc .fc-button-primary:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:not(:disabled):active:focus {
  box-shadow: none !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid $default-border !important;
  border-top: 0px !important;
}

.fc-list-table { 
  td,
  th {
    border-left: 0 !important;
    border-right: 0 !important;
  }
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: $primary-01 !important;
}

.fc-theme-standard .fc-list {
  border: 1px solid $default-border !important;
}

.fc .fc-list-event:hover td {
  // background-color: $light !important;
}

.fc-timegrid-event-harness-inset .fc-timegrid-event,
.fc-timegrid-event.fc-event-mirror,
.fc-timegrid-more-link {
  box-shadow: none !important;
}

.fc-theme-standard .fc-list-day-cushion {
  background-color: $light !important;
}

.fc-theme-standard .fc-scrollgrid {
  border: 1px solid $default-border !important;
}

.fc-theme-bootstrap5 .fc-list,
.fc-theme-bootstrap5 .fc-scrollgrid,
.fc-theme-bootstrap5 td,
.fc-theme-bootstrap5 th {
  border: 1px solid $default-border !important;
}

@media (max-width: 420px) {
  .fc-scroller.fc-scroller-liquid {
    overflow: scroll !important;
  }
}
@media (max-width: 380px) {
  .fc .fc-daygrid-day-bottom {
    font-size: .75em !important;
    padding: 0px 3px 0 !important;
  }
  .fc .fc-daygrid-more-link {
    z-index: 99 !important;
  }
}

@media (max-width: 767.98px) {
  .fc .fc-toolbar {
    display: block !important;
  }

  .fc-toolbar-chunk {
    margin-block-start: 0.5rem;
  }
}

/* End:Full Calendar */

/* Start:Pickers */
.flatpickr-calendar {
  background-color: $custom-white !important;
  box-shadow: $box-shadow !important;
  border: 1px solid $default-border !important;
  font-size: $default-font-size !important;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  color: $black-9 !important;
  fill: $black-9 !important;
}

.flatpickr-monthDropdown-months,
.numInput {
  color: $custom-black !important;
}
.flatpickr-day.today.inRange {
  color: $primary !important;
}
.dayContainer {
  padding: 0.25rem !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: $custom-white !important;
  font-size: 0.813rem;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 1.25rem !important;
  height: 1.25rem !important;
  padding: 0.25rem;
  fill: $primary;
}

.flatpickr-day.inRange {
  box-shadow: none !important;
}

.flatpickr-calendar.open {
  z-index: 99 !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  background-color: $primary-01 !important;
  border-radius: 0.25rem !important;
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after,
.flatpickr-calendar.arrowBottom:before {
  border-top-color: $text-muted !important;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: $text-muted !important;
}

.flatpickr-day.selected.startRange+.endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange+.endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange+.endRange:not(:nth-child(7n+1)) {
  box-shadow: none !important;
}

.flatpickr-day {
  color: $default-text-color !important;
  font-weight: 500 !important;

  &.nextMonthDay,
  &.prevMonthDay {
    opacity: 0.5 !important;
  }
}

.flatpickr-day.today {
  border-color: $primary !important;
  background-color: $primary !important;
  color: $white !important;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: $primary !important;
  color: $white !important;
  border-color: $primary !important;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  background: $default-background !important;
  border-color: $default-border !important;
}

.flatpickr-day.today:hover {
  background-color: $primary !important;
  border-color: $primary !important;
  color: $white !important;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  border-top: 1px solid $default-border !important;
}

.flatpickr-calendar.arrowTop:after,
.flatpickr-calendar.arrowTop:before {
  border-bottom-color: $default-border !important;
}

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: $default-background !important;
}

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  color: $text-muted !important;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {
  inset-inline-start: 0 !important;
}

.flatpickr-months,
.flatpickr-weekdays {
  background-color: $primary-01 !important;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  inset-block-start: 0 !important;
  padding-block-start: 0.313rem !important;
  padding-inline: 0.313rem !important;
  padding-block-end: 0 !important;
  color: $primary !important;
  fill: $primary !important;
}

@media (min-width: 420px) {
  .flatpickr-time .flatpickr-am-pm {
    padding: 0 1.875rem 0 .5rem;
  }
}

.flatpickr-weekdays {
  border-block-end: 1px solid $default-border !important;
}

.numInputWrapper span.arrowUp {
  top: -0.125rem !important;
}

.flatpickr-current-month .numInputWrapper {
  width: 3.5rem !important;
}

.flatpickr-calendar.hasTime {
  width: auto;
}

[dir="rtl"] {
  .flatpickr-months {
    .numInputWrapper span {
      left: 0 !important;
      right: inherit !important;
    }
  }
}

@media (max-width:575.98) {
  .flatpickr-calendar {
    width: 250px !important;
  }
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  margin: -1px 2.25rem 0 0 !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-current-month input.cur-year {
  padding-block: 0 !important;
  padding-inline-start: 0 !important;
  padding-inline-end: 0.5ch !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
}

.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  stroke: $primary;
}

.flatpickr-day {
  border-radius: $default-radius !important;
}

.numInputWrapper:hover {
  background: transparent !important;
}

.numInputWrapper span {
  border: 0px !important;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-block-end-color: $primary !important;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: $white !important;
}

.numInputWrapper span:hover {
  background: transparent !important;
}

.numInputWrapper span.arrowUp:after {
  border-inline-start: 0.25rem solid transparent !important;
  border-inline-end: 0.25rem solid transparent !important;
  border-block-end: 0.25rem solid $primary-05 !important;
  inset-block-start: 75% !important;
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-block-start-color: $primary !important;
}

.numInputWrapper span.arrowDown:after {
  border-inline-start: 0.25rem solid transparent !important;
  border-inline-end: 0.25rem solid transparent !important;
  border-block-start: 0.25rem solid $primary-05 !important;
  inset-block-start: 15% !important;
}

span.flatpickr-weekday {
  color: $primary-08 !important;
  font-weight: 700 !important;
}

.flatpickr-months .flatpickr-month {
  color: $primary !important;
  fill: $primary !important;
}

.flatpickr-monthDropdown-months,
.numInput {
  color: $primary !important;
}

.pcr-app {
  background: $custom-white !important;
}

.pcr-app .pcr-interaction .pcr-result {
  color: $default-text-color !important;
  background: $default-background !important;
}

.theme-container,
.theme-container1,
.theme-container2 {
  button {
    display: none;
  }
}

.pcr-app[data-theme='classic'] .pcr-selection .pcr-color-preview {
  margin-inline-end: 0.75em !important;
}

.pcr-app[data-theme='classic'] .pcr-selection .pcr-color-chooser,
.pcr-app[data-theme='classic'] .pcr-selection .pcr-color-opacity {
  margin-inline-start: 0.75em !important;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  box-shadow: 1px 0 0 $default-border !important;
}

/* End:Pickers */

/* Start:noUi Slider */
.noUi-horizontal .noUi-handle {
  width: 1rem !important;
  height: 1rem !important;
  inset-inline-end: -0.063rem !important;
  top: -0.375rem !important;
}

.noUi-handle:after,
.noUi-handle:before {
  height: 0.35rem !important;
  width: 1px !important;
  inset-inline-start: 0.3rem !important;
  top: 0.25rem !important;
}

.noUi-handle:after {
  inset-inline-start: 0.45rem !important;
}

.noUi-horizontal {
  height: 0.35rem !important;
}

.noUi-vertical {
  width: 0.35rem !important;
}

.noUi-vertical .noUi-handle {
  width: 1rem !important;
  height: 1rem !important;
}

.noUi-target {
  background: $default-background !important;
  border: 1px solid $default-border !important;
  box-shadow: $box-shadow !important;
}

.noUi-handle {
  border: 1px solid $default-border !important;
  background: $custom-white !important;
  box-shadow: $box-shadow !important;
}

#result {
  border: 1px solid $default-border !important;
}

.noUi-handle:after,
.noUi-handle:before {
  background: $black-3 !important;
}

.noUi-marker {
  position: absolute;
  background: $default-border !important;
}

.noUi-tooltip {
  border: 1px solid $default-border !important;
  border-radius: $default-radius !important;
  background: $custom-white !important;
  color: $default-text-color !important;
  padding: 0.25rem 0.75rem !important;
  font-size: $default-font-size !important;
  line-height: 1 !important;
}

#slider-fit {
  padding: 0 1rem;
}

.noUi-connect {
  background: $primary !important;
}

.noUi-vertical .noUi-handle {
  bottom: -0.275rem !important;
  inset-inline-end: -0.375rem;
}

#secondary-colored-slider {
  .noUi-connect {
    background-color: $secondary !important;
  }
}

#warning-colored-slider {
  .noUi-connect {
    background-color: $warning !important;
  }
}

#info-colored-slider {
  .noUi-connect {
    background-color: $info !important;
  }
}

#success-colored-slider {
  .noUi-connect {
    background-color: $success !important;
  }
}

#danger-colored-slider {
  .noUi-connect {
    background-color: $danger !important;
  }
}

#slider-round {
  height: 0.625rem !important;

  .noUi-handle {
    height: 1.125rem !important;
    width: 1.125rem !important;
    inset-block-start: -0.313rem !important;
    inset-inline-end: -0.563rem;
    /* half the width */
    border-radius: 50px !important;
    background-color: $primary !important;
    border: 1px solid $custom-white !important;

    &:before,
    &:after {
      display: none !important;
    }
  }
}

#slider-square {
  border-radius: 0 !important;

  .noUi-handle {
    border: 0 !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    background: $primary !important;
    height: 1.125rem !important;
    width: 1.125rem !important;
    inset-block-start: -0.45rem !important;
    inset-inline-end: -0.563rem !important;

    &:before,
    &:after {
      display: none !important;
    }
  }
}

#color1,
#color2,
#color3 {
  margin: 0.625rem !important;
  display: inline-block !important;
  height: 12.5rem !important;
}

#colorpicker {
  height: 15rem !important;
  width: 19.375rem !important;
  margin: 0 auto !important;
  padding: 0.625rem !important;
  border: 1px solid $default-border !important;
}

#result {
  margin: 4.25rem 0 4.25rem 4rem;
  height: 6.25rem;
  width: 6.25rem;
  display: inline-block;
  vertical-align: top;
  color: $gray-5;
  background: $gray-5;
  border: 1px solid $white;
  box-shadow: 0 0 0.625rem;
}

#color1 .noUi-connect {
  background: $danger !important;
}

#color2 .noUi-connect {
  background: $secondary !important;
}

#color3 .noUi-connect {
  background: $primary !important;
}

#slider-hide .noUi-tooltip {
  display: none;
}

#slider-hide .noUi-active .noUi-tooltip {
  display: block;
}

.c-1-color {
  background: $secondary !important;
}

.c-2-color {
  background: $warning !important;
}

.c-3-color {
  background: $info !important;
}

.c-4-color {
  background: $danger !important;
}

.c-5-color {
  background: $indigo !important;
}

#slider-toggle {
  height: 3.125rem;
}

#slider-toggle.off .noUi-handle {
  border-color: $success !important;
}

/* End:noUi Slider */

/* Start::Gallery */
.glightbox {
  overflow: hidden;
}

@media (min-width: 769px) {
  .gslide-image img {
    border-radius: $default-radius;
  }

  .glightbox-clean .gclose,
  .glightbox-clean .gnext,
  .glightbox-clean .gprev {
    background-color: rgba(255, 255, 255, 0.05) !important;
    width: 2.5rem !important;
    height: 2.5rem !important;
    padding: .875rem;
  }
}

/* End::Gallery */

/* Start::Calendar */
#external-events .fc-event {
  cursor: move;
  margin: 0 0 0.4rem 0;
  padding: 0.375rem 0.75rem;
  font-size: 0.75rem;
  border-radius: 0.35rem;
}

#calendar-container {
  position: relative;
  z-index: 1;
}

#calendar {
  max-width: 68.75rem;
  margin: 1.25rem auto;
}

/* End::Calendar */

/* Start::Leaflet Maps */
#map,
#map1,
#map-popup,
#map-custom-icon,
#interactive-map {
  height: 18.75rem;
  z-index: 8;
}

/* End::Leaflet Maps */

/* Start::Vector Maps */
#vector-map,
#marker-map,
#marker-image-map,
#lines-map,
#us-map,
#canada-map,
#spain-map,
#russia-map {
  height: 21.875rem;
}

.jvm-tooltip {
  background-color: #1a1c1e !important;
}

#vector-map,
#marker-map,
#marker-image-map,
#lines-map,
#visitors-countries,
#users-map {
  #jvm-regions-group path {
    fill: $light !important;
  }
}

.jvm-zoom-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

#jvm-markers-labels-group text {
  fill: $text-muted;
}

/* End::Vector Maps */

/* Start::Google Maps */
#google-map,
#google-map-overlay,
#map-layers,
#map-markers,
#streetview-map,
#map-geofencing {
  height: 18.75rem;
}

.google-map-overlay {
  display: block;
  text-align: center;
  color: $white;
  font-size: 1.25rem;
  line-height: 0.875rem;
  opacity: 0.8;
  background: $primary;
  border: solid 0.188rem $primary;
  border-radius: 0.25rem;
  box-shadow: 0.125rem 0.125rem 0.625rem $black-3;
  text-shadow: 0.063rem 0.063rem 0.063rem $gray-6;
  padding: 0 0.25rem;
}

.google-overlay_arrow {
  inset-inline-start: 50%;
  margin-inline-start: -1rem;
  width: 0;
  height: 0;
  position: absolute;
}

.google-overlay_arrow.above {
  inset-block-end: -0.938rem;
  border-inline-start: 0.938rem solid transparent;
  border-inline-end: 1rem solid transparent;
  border-block-start: 1rem solid #336699;
}

.google-overlay_arrow.below {
  inset-block-start: -0.938rem;
  border-inline-start: 1rem solid transparent;
  border-inline-end: 1rem solid transparent;
  border-block-end: 1rem solid #336699;
}

/* End::Google Maps */

/* Start::Apex Charts */
#pie-basic,
#donut-update,
#pie-monochrome,
#donut-gradient,
#donut-pattern,
#pie-image,
#polararea-basic,
#polararea-monochrome {
  .apexcharts-canvas {
    margin: 0 auto;
  }
}

.apexcharts-legend-text {
  color: $default-text-color !important;
  padding-inline-start: .9375rem !important;
  margin-inline-start: -0.625rem !important;
}

.apexcharts-text {

  &.apexcharts-yaxis-label,
  &.apexcharts-xaxis-label {
    tspan {
      fill: $text-muted;
    }
  }
}

.apexcharts-canvas .apexcharts-series.apexcharts-heatmap-series rect {
  stroke: $custom-white;
}

.apexcharts-canvas .apexcharts-series-markers.apexcharts-series-bubble circle {
  stroke: $custom-white;
}

.apexcharts-yaxis .apexcharts-text {
  fill: $text-muted;
}

/* End::Apex Charts */

/* Start::Chartjs Charts */
.chartjs-chart {
  max-height: 18.75rem;
}

/* Start::Chartjs Charts */

/* Start::Apex Column Charts */
#chart-year,
#chart-quarter {
  width: 96%;
  max-width: 48%;
  box-shadow: none;
  padding-inline-start: 0;
  padding-block-start: 1.25rem;
  background: $white;
  border: 1px solid #ddd;
}

#chart-year {
  float: left;
  position: relative;
  transition: 1s ease transform;
  z-index: 3;
}

#chart-year.chart-quarter-activated {
  transform: translateX(0);
  transition: 1s ease transform;
}

#chart-quarter {
  float: left;
  position: relative;
  z-index: -2;
  transition: 1s ease transform;
}

#chart-quarter.active {
  transition: 1.1s ease-in-out transform;
  transform: translateX(0);
  z-index: 1;
}

@media screen and (min-width: 480px) {
  #chart-year {
    transform: translateX(50%);
  }

  #chart-quarter {
    transform: translateX(-50%);
  }
}

/* End::Apex Column Charts */

/* Start::ECharts */
.echart-charts {
  height: 20rem;
}

/* End::ECharts */

/* Start::Simplebar */
.simplebar-scrollbar:before {
  background: $gray-4 !important;
  border-radius: 0.3rem !important;
  inset-inline-end: 0 !important;
  width: 0.375rem;
}

.simplebar-track.simplebar-horizontal {
  display: none;
}

/* End::Simplebar */

/* Start::dropzone */
.dropzone {
  border: 0.125rem dashed $default-border !important;
  background-color: transparent !important;

  .dz-message .dz-button {
    font-size: 1.25rem !important;
    color: $default-text-color !important;
  }
}

.dropzone .dz-preview {
  border-radius: 1.25rem;
}

/* End::dropzone */

/* Start::filepond */
.filepond--drop-label {
  color: $default-text-color !important;
  border-radius: 0.3rem !important;
}

.filepond--credits {
  display: none;
}

.filepond--panel-root {
  background-color: $custom-white !important;
  border: 0.125rem dashed $default-border !important;
}

.filepond--drop-label.filepond--drop-label label {
  padding: 1.5em !important;
  font-size: $default-font-size;
}

.filepond--root {
  margin-block-end: 0 !important;
}

.filepond--file {
  background-color: $primary !important;
}

.single-fileupload {
  width: 8rem !important;
  height: 8rem !important;
  margin: 0 auto !important;
}

/* End::filepond */

/* Start:: quill editor */
.ql-bubble .ql-editor blockquote {
  border-inline-start: 4px solid $default-background !important;
}

.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  border: 1px solid $default-border !important;
}

.ql-snow .ql-picker {
  color: $default-text-color !important;
}

.ql-snow .ql-stroke,
.ql-snow .ql-stroke.ql-fill {
  stroke: $default-text-color !important;
}

.ql-snow .ql-fill {
  fill: $default-text-color !important;
}

.ql-toolbar.ql-snow+.ql-container.ql-snow {
  border-top: 0px !important;
}

.ql-snow .ql-picker-options .ql-picker-item {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.ql-editor {
  min-height: 15.62rem !important;
}

.ql-snow .ql-formats {
  border: 1px solid $default-border;
  border-radius: $default-radius;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: $default-border !important;
  border-radius: $default-radius !important;
}

.ql-snow .ql-picker-options {
  background-color: $custom-white !important;
}

.ql-snow .ql-tooltip {
  background-color: $custom-white !important;
  border: 1px solid $default-border !important;
  box-shadow: $box-shadow !important;
  color: $default-text-color !important;
}

.ql-snow .ql-tooltip input[type="text"] {
  border: 1px solid $default-border !important;
  background-color: $default-background !important;
  color: $default-text-color !important;
  outline: 0;
}

.ql-snow .ql-tooltip {
  transform: translatex(12.5rem) !important;
  z-index: 100 !important;
}

.ql-toolbar.ql-snow {
  border-radius: 0.3rem 0.3rem 0 0 !important;
}

.ql-snow .ql-picker-label {
  padding-inline-start: 0.5rem !important;
  padding-inline-end: 0.125rem !important;
}

.ql-snow .ql-formats .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  inset-inline-end: 0 !important;
  inset-inline-start: auto !important;
}

.ql-container {
  border-radius: 0 0 0.3rem 0.3rem !important;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: $default-text-color !important;
}

.ql-editor {
  text-align: start !important;
}

.ql-container {
  font-family: $default-font-family !important;
}

.ql-snow .ql-editor {
  padding: 1.25rem;
}

.ql-bubble {
  border: 1px solid $default-border !important;
  border-radius: $default-radius !important;
}

.ql-editor li:not(.ql-direction-rtl)::before {
  margin-inline-start: -1.5em !important;
  margin-inline-end: 0.3em !important;
  text-align: start !important;
}

.ql-editor ol li:not(.ql-direction-rtl),
.ql-editor ul li:not(.ql-direction-rtl) {
  padding-inline-start: 1.5em !important;
}

.ql-toolbar.ql-snow .ql-formats {
  margin: 0.25rem;
}

[dir="rtl"] {
  .ql-tooltip.ql-editing {
    left: 0 !important;
    top: 0 !important;
  }

  .ql-bubble .ql-toolbar .ql-formats:first-child {
    margin-right: 12px;
  }

  .ql-bubble .ql-toolbar .ql-formats {
    margin: 8px 0px 8px 12px;
  }
}

/* end:: quill editor */

/* Start:: select2 */
.select2.select2-container {
  width: 100% !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: $default-text-color !important;
  line-height: 2.25rem !important;
  border: 1px solid $input-border !important;
  border-radius: $default-radius !important;
}

.select2-container--default .select2-selection--single {
  background-color: $form-control-bg !important;
  border: 0px solid $input-border !important;
  border-radius: $default-radius !important;
}

.select2-container .select2-selection--single,
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 2.25rem !important;
}

.select2-dropdown {
  background-color: $custom-white !important;
  border: 1px solid $input-border !important;
  border-radius: $default-radius !important;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid $input-border !important;
  border-radius: $default-radius !important;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: $default-background !important;
  color: $default-text-color !important;
}

.select2-results__option--selectable {
  font-size: 0.813rem;
}

.select2-container--default .select2-results__option--selected {
  background-color: $primary !important;
  color: $white !important;

  &.select2-results__option--highlighted {
    background-color: $primary !important;
    color: $white !important;
  }
}

.select2-search__field {
  background-color: $custom-white !important;
  color: $default-text-color !important;

  &:focus-visible {
    outline: none !important;
  }
}

.select2-container--default .select2-selection--multiple {
  background-color: $form-control-bg !important;
  border: 1px solid $input-border !important;
  border-radius: $default-radius !important;
}

.select2-container .select2-selection--multiple {
  min-height: 2.25rem !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: $primary !important;
  color: $white !important;
  border: 1px solid $primary !important;
  border-radius: 0.15rem !important;
  margin-block-start: 0.375rem !important;
}

.select2-selection--multiple .select2-search__field {
  background-color: transparent !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  border-inline-end: 1px solid rgba(255, 255, 255, 0.1) !important;
  color: $white !important;
  inset-block-start: -0.3rem !important;
  font-weight: 500 !important;
  font-size: 1.125rem !important;
}

.select2-selection--multiple .select2-selection__choice__display {
  font-size: 0.75rem !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover,
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus {
  background-color: $primary !important;
}

.select2-results__option span img,
.select2-selection__rendered span img {
  width: 1.45rem;
  height: 1.45rem;
  border-radius: 1.25rem;
  margin-inline-end: 0.25rem;
  box-shadow: $box-shadow;
}

.select2-container .select2-search--inline .select2-search__field {
  margin-block-start: 0.5rem !important;
}

.select2-container--disabled {

  &.select2-container--default .select2-selection--single .select2-selection__rendered,
  .select2-selection.select2-selection--multiple {
    background-color: $default-background !important;
  }
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  font-weight: 400 !important;
  height: 1.25rem !important;
  font-size: 1.5625rem !important;
  width: 1.25rem !important;
  position: absolute !important;
  inset-inline-end: .625rem !important;
}

.select2-selection__clear {
  color: $text-muted;
}
.select2-dropdown {
  z-index: 10 !important;
}
[dir="rtl"] {
  .select2-container--default .select2-selection--single .select2-selection__clear {
    margin-left: 20px !important;
    padding-left: 0px !important; 
  }
}
/* End:: select2 */

/* Start:: tabulator */
.tabulator .tabulator-header .tabulator-headers .tabulator-col .tabulator-col-content {
  padding: 0.75rem !important;
  vertical-align: middle !important;
  line-height: 1.462 !important;
  font-size: 0.813rem !important;
}

.tabulator .tabulator-header {
  border-bottom: 0px !important;
  background-color: $custom-white !important;
  color: $default-text-color !important;
  font-weight: 600 !important;
}

.tabulator .tabulator-header .tabulator-col {
  border-inline-end: 1px solid $default-border !important;
  background: $custom-white !important;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort="descending"] .tabulator-col-content .tabulator-col-sorter .tabulator-arrow {
  border-top: 0.375rem solid $text-muted !important;
  color: $text-muted !important;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort="ascending"] .tabulator-col-content .tabulator-col-sorter .tabulator-arrow {
  border-bottom: 0.375rem solid $text-muted !important;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort="none"] .tabulator-col-content .tabulator-col-sorter {
  color: $light !important;
}

.tabulator {
  border: 1px solid $default-border !important;
  background-color: $custom-white !important;
  font-size: $default-font-size !important;
  text-align: start !important;

  .tabulator-footer {
    border-block-start: 1px solid $default-border !important;
    background-color: $custom-white !important;
    color: $default-text-color !important;
    font-weight: 600 !important;

    .tabulator-footer-contents {
      padding: 0.75rem !important;
    }

    .tabulator-page-size {
      padding: 0.375rem 0.25rem 0.375rem 0.25rem !important;
      border: 1px solid $default-border !important;
      border-radius: $default-radius !important;
      background-color: $custom-white !important;
      color: $default-text-color !important;

      &:focus-visible {
        outline: 0 !important;
      }
    }

    .tabulator-paginator {
      text-align: end !important;
      color: $default-text-color !important;
    }
  }
}

.tabulator-row .tabulator-cell {
  border-inline-end: 1px solid $default-border !important;
}

.tabulator-row {
  border-block-start: 1px solid $default-border !important;

  .tabulator-cell {
    padding: 0.75rem !important;
  }

  &.tabulator-row-even,
  &.tabulator-row-odd {
    background-color: $custom-white !important;
    color: $default-text-color !important;
  }
}

.tabulator .tabulator-footer .tabulator-page.active {
  color: $primary !important;
}

.tabulator .tabulator-footer .tabulator-page {
  padding: 0.375rem 0.75rem !important;
  border: 1px solid $default-border !important;
  border-radius: $default-radius !important;
  background: $custom-white !important;
  color: $default-text-color !important;
}

/* End:: tabulator */

/* Start:: grid js tables */
.gridjs-table {
  width: 100%;
}

table.gridjs-table {
  text-align: start !important;
  font-size: 0.813rem !important;
  font-weight: 500 !important;
}

.gridjs-wrapper {
  box-shadow: none !important;
  border-radius: 0 !important;

  &:nth-last-of-type(2) {
    border-radius: 0 !important;
  }
}

.gridjs-container {
  color: $default-text-color !important;
}

th.gridjs-th {
  background-color: $custom-white !important;
  border: 1px solid $default-border !important;
  color: $default-text-color !important;
  padding: 0.75rem !important;
}

td.gridjs-td {
  border: 1px solid $default-border !important;
  padding: 0.75rem !important;
}

.gridjs-tbody,
td.gridjs-td {
  background-color: $custom-white !important;
}

.gridjs-footer {
  background-color: $custom-white !important;
  border-bottom-width: 0 !important;
  border-color: transparent !important;
  border-radius: 0 !important;
  border-top: 0 !important;
  box-shadow: none !important;
  padding: 0.75rem 0 0 0 !important;
}

.gridjs-pagination {
  color: $default-text-color !important;
}

.gridjs-pagination .gridjs-pages button:first-child {
  border-bottom-left-radius: $default-radius !important;
  border-top-left-radius: $default-radius !important;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.gridjs-pagination .gridjs-pages button:last-child {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-right-radius: $default-radius !important;
  border-top-right-radius: $default-radius !important;
}

@media (max-width:575.98px) {
  .gridjs-search-input {
    width: 12.5rem !important;
  }
}

[dir="rtl"] {
  .gridjs-pagination .gridjs-pages button:first-child {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-right-radius: $default-radius !important;
    border-top-right-radius: $default-radius !important;
  }

  .gridjs-pagination .gridjs-pages button:last-child {
    border-bottom-left-radius: $default-radius !important;
    border-top-left-radius: $default-radius !important;
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
}

.gridjs-pagination .gridjs-pages button:disabled,
.gridjs-pagination .gridjs-pages button:hover:disabled,
.gridjs-pagination .gridjs-pages button[disabled] {
  background-color: $custom-white !important;
  color: $default-text-color !important;
}

.gridjs-pagination .gridjs-pages button {
  background-color: $custom-white !important;
  border: 1px solid $default-border !important;
  padding: 0.375 0.75rem !important;
  color: $default-text-color !important;

  &:focus {
    box-shadow: none !important;
    margin-inline-end: 0 !important;
  }
}

.gridjs-pagination .gridjs-pages button.gridjs-currentPage {
  font-weight: 600 !important;
  background-color: $primary !important;
  color: $white !important;
}

.gridjs-pagination .gridjs-pages {
  float: end !important;
}

input.gridjs-input {
  background-color: $custom-white !important;
  border: 1px solid $default-border !important;
  border-radius: $default-radius !important;
  font-size: $default-font-size !important;
  padding: 0.375rem 0.75rem !important;
  line-height: 1.6 !important;
  color: $default-text-color !important;

  &:focus {
    box-shadow: none !important;
    border: 1px solid $primary !important;
  }
}

button.gridjs-sort {
  float: end !important;
  height: 1.25rem !important;
  width: 0.625rem !important;
}

button.gridjs-sort-desc {
  background-position-y: 54% !important;
  background-size: 0.5rem !important;
}

button.gridjs-sort-asc {
  background-position-y: 44% !important;
  background-size: 0.5rem !important;
}

th.gridjs-th-fixed {
  background-color: $light !important;
}

#grid-header-fixed {
  .gridjs-wrapper {
    border-block-start: 1px solid $default-border !important;
    border-block-end: 1px solid $default-border !important;
  }

  .gridjs-container .gridjs-wrapper .gridjs-thead .gridjs-tr th {
    top: -1px !important;
  }
}

/* End:: grid js tables */

/* Start:: bootstrap5 datatables */
.dataTables_wrapper .dataTables_paginate {
  margin: 0;
  text-align: end;

  .pagination {
    margin-block-end: 0;
    justify-content: end;
  }
}

div.dataTables_scrollBody>table#datatable-basic {
  margin-block-end: 0.375rem !important;
}

.dataTables_filter {
  text-align: end !important;
}

.dataTables_info {
  padding-block-start: 0.625rem;
}

table.dataTable>thead .sorting:before,
table.dataTable>thead .sorting_asc:before,
table.dataTable>thead .sorting_asc_disabled:before,
table.dataTable>thead .sorting_desc:before,
table.dataTable>thead .sorting_desc_disabled:before {
  content: "\F235" !important;
  font-family: bootstrap-icons !important;
  position: absolute;
  inset-inline-end: 0.8rem !important;
  top: 0.813rem !important;
  font-size: 0.5rem !important;
}

table.dataTable>thead .sorting:after,
table.dataTable>thead .sorting_asc:after,
table.dataTable>thead .sorting_asc_disabled:after,
table.dataTable>thead .sorting_desc:after,
table.dataTable>thead .sorting_desc_disabled:after {
  content: "\F229" !important;
  font-family: bootstrap-icons !important;
  position: absolute;
  inset-inline-end: 0.8rem !important;
  top: 1.25rem !important;
  font-size: 0.5rem !important;
}

table.dataTable>thead .sorting,
table.dataTable>thead .sorting_asc,
table.dataTable>thead .sorting_desc,
table.dataTable>thead .sorting_asc_disabled,
table.dataTable>thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative;
}

table.dataTable>thead .sorting:before,
table.dataTable>thead .sorting:after,
table.dataTable>thead .sorting_asc:before,
table.dataTable>thead .sorting_asc:after,
table.dataTable>thead .sorting_desc:before,
table.dataTable>thead .sorting_desc:after,
table.dataTable>thead .sorting_asc_disabled:before,
table.dataTable>thead .sorting_asc_disabled:after,
table.dataTable>thead .sorting_desc_disabled:before,
table.dataTable>thead .sorting_desc_disabled:after {
  position: absolute;
  display: block;
  opacity: 0.2;
}

table.dataTable>thead .sorting_asc:before,
table.dataTable>thead .sorting_desc:after {
  opacity: 0.8;
}

div.dataTables_wrapper div.dataTables_length select {
  width: auto;
  display: inline-block;
  margin: 0 0.25rem;
}

.dataTables_wrapper {
  .dataTables_scrollHead {
    table.dataTable {
      margin-block-end: 0;
    }
  }

  .dataTables_scrollBody {
    #datatable-basic {
      margin-block-start: -3px;
      border-block-start-color: transparent !important;
    }

    table.dataTable>thead .sorting:before,
    table.dataTable>thead .sorting:after,
    table.dataTable>thead .sorting_asc:before,
    table.dataTable>thead .sorting_asc:after,
    table.dataTable>thead .sorting_desc:before,
    table.dataTable>thead .sorting_desc:after,
    table.dataTable>thead .sorting_asc_disabled:before,
    table.dataTable>thead .sorting_asc_disabled:after,
    table.dataTable>thead .sorting_desc_disabled:before,
    table.dataTable>thead .sorting_desc_disabled:after {
      display: none;
    }
  }
}

div.dt-button-info {
  background-color: $custom-white !important;
  border: 1px solid $default-border !important;
  h2 {
    background-color: $custom-white !important;
    border-bottom: 1px solid $default-border !important;
  }
}

@media (max-width: 767.98px) {
  .data-table-btn {
    margin: 0.5625rem 0 0.5rem 0 !important;
  }

  .dataTables_length,
  .dataTables_filter,
  .dataTables_info {
    text-align: center;
  }

  .dataTables_filter {
    margin-block-start: 0.5rem;
  }

  .dataTables_paginate .pagination {
    justify-content: center !important;
  }

  .dataTables_info {
    padding-block-start: 0;
    padding-block-end: 0.5rem;
  }

  div.dtr-modal div.dtr-modal-display {
    width: 95% !important;
    height: 95% !important;
    background-color: $custom-white !important;
    border: 1px solid $default-border !important;
    box-shadow: $box-shadow !important;
  }

  div.dataTables_wrapper div.dataTables_length,
  div.dataTables_wrapper div.dataTables_filter,
  div.dataTables_wrapper div.dataTables_info,
  div.dataTables_wrapper div.dataTables_paginate {
    text-align: center !important;
  }
}

@media (max-width: 575.98px) {
  .dataTables_paginate .pagination {
    .paginate_button .page-link {
      font-size: 0.625rem;
      padding: 0.25rem 0.5rem;
    }
  }
}

table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control {
  padding-inline-start: 1.875rem !important;
}

table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:before,
table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control:before {
  color: $white !important;
  border: 0.15em solid $white !important;
  box-shadow: 0 0 0.2em $primary !important;
  background-color: $primary !important;
}

table.dataTable.dtr-inline.collapsed>tbody>tr.parent>td.dtr-control:before,
table.dataTable.dtr-inline.collapsed>tbody>tr.parent>th.dtr-control:before {
  content: "-";
  background-color: $success !important;
}

table.dataTable>tbody>tr.child ul.dtr-details>li:first-child {
  padding-block-start: 0 !important;
}

table.dataTable>tbody>tr.child ul.dtr-details>li {
  border-block-end: 1px solid $default-border !important;
}

table.dataTable>tbody>tr.child span.dtr-title {
  min-width: 4.688rem !important;
}

div.dtr-modal div.dtr-modal-close {
  top: 0px !important;
  border: 0px solid $default-border !important;
  font-size: 1.5rem !important;
  background-color: transparent !important;
}

div.dtr-modal div.dtr-modal-background {
  background: rgba(0, 0, 0, 0.3) !important;
}

.dtr-modal-content h2 {
  font-size: 0.875rem !important;
  font-weight: 600 !important;
}

.dt-button {
  font-size: 0.8125rem !important;
  border-radius: 0.3rem !important;
  box-shadow: none !important;
  font-weight: 500 !important;
  padding: 0.375rem 0.75rem !important;
  background-color: $primary;
  color: $white;
  border: 0;
}

.dt-buttons {
  float: left !important;
}

table.dataTable thead>tr>th.sorting,
table.dataTable thead>tr>th.sorting_asc,
table.dataTable thead>tr>th.sorting_desc,
table.dataTable thead>tr>th.sorting_asc_disabled,
table.dataTable thead>tr>th.sorting_desc_disabled,
table.dataTable thead>tr>td.sorting,
table.dataTable thead>tr>td.sorting_asc,
table.dataTable thead>tr>td.sorting_desc,
table.dataTable thead>tr>td.sorting_asc_disabled,
table.dataTable thead>tr>td.sorting_desc_disabled {
  padding-inline-end: 1.625rem;
}

table.dataTable thead th,
table.dataTable thead td,
table.dataTable tfoot th,
table.dataTable tfoot td {
  text-align: start !important;
}

table.table-bordered.dataTable th:first-child,
table.table-bordered.dataTable th:first-child,
table.table-bordered.dataTable td:first-child,
table.table-bordered.dataTable td:first-child {
  border-inline-start-width: 1px !important;
}

table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable td:last-child,
table.table-bordered.dataTable td:last-child {
  border-inline-end-width: 1px !important;
}

[dir="rtl"] {
  div.dtr-modal div.dtr-modal-close {
    left: 6px;
    right: auto;
  }

  div.dataTables_wrapper div.dataTables_filter input {
    margin-right: 0.5rem;
    margin-left: 0;
  }

  table.table-bordered.dataTable th:first-child,
  table.table-bordered.dataTable th:first-child,
  table.table-bordered.dataTable td:first-child,
  table.table-bordered.dataTable td:first-child {
    border-right-width: 1px;
    border-left-width: 0;
  }

  table.table-bordered.dataTable th:last-child,
  table.table-bordered.dataTable th:last-child,
  table.table-bordered.dataTable td:last-child,
  table.table-bordered.dataTable td:last-child {
    border-inline-start-width: 1px;
  }

  div.table-responsive>div.dataTables_wrapper>div.row>div[class^="col-"]:first-child {
    padding-inline-end: 0;
  }

  div.table-responsive>div.dataTables_wrapper>div.row>div[class^="col-"]:last-child {
    padding-inline-start: 0;
  }

  .dt-buttons {
    float: right !important;
  }
}

table.dataTable>tbody>tr.selected>* {
  background-color: $primary-01;
  color: $default-text-color !important;
  box-shadow: none !important;
}

@media (min-width: 768px) {
  .data-table-btn {
    position: absolute;
    inset-inline-start: 12.5rem;
    z-index: 1;
  }
}

#file-export_wrapper {
  .dt-buttons {
    button {
      margin: 0.25rem;
    }
  }
}

.data-table-btn {
  margin: -0.4375rem 0 0 0;
}

/* End:: bootstrap5 datatables */

/* Start:: sweet alerts */
.swal2-container {
  .swal2-title {
    font-size: 1.15rem;
    padding: 2rem 2rem 0.5rem;
  }

  .swal2-footer {
    padding: 1.5rem 1.5rem 0;
  }

  .swal2-popup {
    background: $custom-white;
    color: $default-text-color;
    padding: 0 0 2rem;
  }

  .swal2-center>.swal2-popup {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .swal2-actions {
    margin: 1rem auto 0;
  }

  .swal2-styled.swal2-confirm {
    margin: 0;
    padding: 0.375em 0.75rem;
    box-shadow: none;
    font-size: 0.8125rem;
    border-radius: 0.3rem;
  }

  .swal2-styled.swal2-confirm:focus,
  .swal2-styled.swal2-cancel:focus,
  .swal2-styled.swal2-deny:focus {
    box-shadow: none;
  }

  .swal2-styled.swal2-confirm {
    background-color: $primary !important;
    color: $white;
  }

  .swal2-footer {
    border-top: 1px solid $default-border;
  }

  .swal2-icon {
    width: 4rem;
    height: 4rem;
    margin: 2rem auto 0rem;
  }

  .swal2-icon.swal2-question {
    border-color: $success;
    color: $success;
  }

  .swal2-icon.swal2-error {
    border-color: $danger;
    color: $danger;
  }

  .swal2-icon.swal2-info {
    border-color: $info;
    color: $info;
  }

  .swal2-icon.swal2-warning {
    border-color: $warning;
    color: $warning;
  }

  .swal2-icon .swal2-icon-content {
    font-size: 3rem;
  }

  .swal2-image {
    border-radius: $default-radius;
  }

  .swal2-html-container {
    margin: 0rem 1.6rem 0.3rem;
    font-size: 0.8rem;
    color: $text-muted;
  }

  .swal2-icon.swal2-error [class^="swal2-x-mark-line"][class$="left"] {
    inset-inline-start: 1em;
  }

  .swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
    top: 1.9em;
    width: 2em;
    height: 0.3em;
    background-color: $danger;
  }

  .swal2-icon.swal2-error [class^="swal2-x-mark-line"][class$="right"] {
    inset-inline-end: 1em;
    inset-block-start: 1.875rem;
  }

  .swal2-close:focus {
    box-shadow: none;
  }

  .swal2-deny,
  .swal2-cancel {
    margin-inline-start: 0.625rem;
  }

  .swal2-close {
    font-size: 2rem;
    color: $text-muted;
    opacity: 0.5;
  }

  .swal2-close:hover {
    color: $primary;
  }

  .swal2-styled.swal2-deny {
    background-color: $danger;
    padding: 0.375em 0.75rem;
    box-shadow: none;
    font-size: 0.8125rem;
    border-radius: 0.3rem;
  }

  .swal2-styled.swal2-cancel {
    background-color: $light !important;
    color: $default-text-color;
    padding: 0.375em 0.75rem;
    box-shadow: none;
    font-size: 0.8125rem;
    border-radius: 0.3rem;
  }

  .swal2-icon.swal2-success [class^="swal2-success-line"][class$="long"] {
    top: 2.05em;
    inset-inline-end: 0.45em;
    width: 2em;
  }

  .swal2-icon.swal2-success [class^="swal2-success-line"][class$="tip"] {
    top: 2.375em;
    inset-inline-start: 0.9125em;
    width: 1.2625em;
  }

  .swal2-file:focus,
  .swal2-input:focus,
  .swal2-textarea:focus {
    border: 1px solid $default-border;
    box-shadow: none;
  }
}

[dir="rtl"] {
  .swal2-container .swal2-icon.swal2-success [class^=swal2-success-line][class$=tip] {
    inset-inline-start: 1.9125em;
  }
}

/* End:: sweet alerts */

/* Start:: swiper js */
.swiper {
  border-radius: $default-radius;
}

[dir="rtl"] {
  .swiper {
    direction: ltr;
  }
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-next,
.swiper-button-prev {
  width: 1.563rem !important;
  height: 1.563rem !important;
  color: $white !important;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: $default-radius;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 0.75rem !important;
  font-weight: 800 !important;
}

.swiper-horizontal1 .swiper-slide {
  height: auto;
}

.swiper-pagination-bullet {
  width: 1.25rem !important;
  height: 0.25rem !important;
  border-radius: $default-radius !important;
  background: $white !important;
}

.swiper-pagination-bullet-active {
  background: $white !important;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: $success !important;
}

.swiper-pagination {
  color: $white;
}

.custom-pagination {
  .swiper-pagination-bullet {
    width: 1.5rem !important;
    height: 1.5rem !important;
    padding: 0.188rem;
    background-color: rgba(255, 255, 255, 0.3) !important;
    color: $white !important;
    opacity: 1 !important;

    &.swiper-pagination-bullet-active {
      background-color: $white !important;
      color: $black !important;
    }
  }
}

.swiper-scrollbar {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.swiper-scrollbar-drag {
  background-color: $white !important;
}

.swiper.vertical {
  height: 21.875rem;
}

.swiper-preview {
  height: 80%;
  width: 100%;
}

.swiper-view {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.swiper-view .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.swiper-view .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-preview .swiper-wrapper {
  margin-block-end: 0.65rem;
}

.swiper-thumbs .swiper-slide img {
  border-radius: $default-radius;
}

/* End:: swiper js */

/* Start:: prism js */
pre[class*=language-]:after,
pre[class*=language-]:before {
  display: none !important;
}

pre[class*=language-]>code {
  border-inline-start: 1px solid $default-border !important;
  box-shadow: none !important;
  background-color: $light !important;
  border: 1px solid $default-border;
  border-radius: $default-radius;
  background-image: none !important;
  white-space: pre-wrap;
}

:not(pre)>code[class*=language-],
pre[class*=language-] {
  background-color: $custom-white !important;
  margin-bottom: 0 !important;
  max-height: 400px;
  overflow-y: hidden !important;
  border-radius: $default-radius;
}

code[class*=language-],
pre[class*=language-] {
  color: $default-text-color !important;
  font-size: 0.82rem !important;
}

pre[class*=language-] {
  margin: 0 !important;
}

code[class*=language-] {
  padding: 1rem !important;
}

.prism-toggle {
  margin: 0.25rem;
}

[dir="rtl"] {
  pre[class*=language-]>code {
    border-left: 0;
    text-align: right;
  }
}

/* End:: prism js */

/* Start:: Draggable Cards */
#draggable-left .card,
#draggable-right .card {
  touch-action: none;
}

/* End:: Draggable Cards */
.ql-editor {
  overflow-y: inherit !important;
}
.fullcalendar-events-activity li {
  margin-block-end: 0.75rem;
  font-size: .8125rem;
  padding: 0.25rem 1rem 0.25rem 2rem;
  position: relative;
}
.fullcalendar-events-activity li::before {
  position: absolute;
  content: "";
  width: 0.75rem;
  height: 0.75rem;
  border: 0.125rem solid var(--primary03);
  border-radius: 3.125rem;
  background-color: var(--custom-white);
  inset-inline-start: 0.25rem;
  inset-block-start: 0.563rem;
}
.fullcalendar-events-activity li::after {
  position: absolute;
  content: "";
  height: 100%;
  background-color: transparent;
  border-inline-end: 2px dashed var(--primary01);
  inset-inline-start: 0.563rem;
  inset-block-start: 1.25rem;
}