/*---- Tags-----*/

.tag {
	font-size: 0.75rem;
	color: $default-text-color;
	background-color: $default-background;
	border-radius: 3px;
	padding: 0 .5rem;
	line-height: 2em;
	display: -ms-inline-flexbox;
	display: inline-flex;
	cursor: default;
	font-weight: 400;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

a.tag {
	text-decoration: none;
	cursor: pointer;
	transition: .3s color, .3s background;

	&:hover {
		background-color: rgba(110, 118, 135, 0.2);
		color: inherit;
	}
}

.tag-addon {
	display: inline-block;
	padding: 0 .5rem;
	color: inherit;
	text-decoration: none;
	background: rgba(0, 0, 0, 0.06);
	margin-block-start: 0px;
	margin-inline-end: -0.5rem;
	margin-block-end: 0px;
	margin-inline-start: 0.5rem;
	text-align: center;
	min-width: 1.5rem;

	&:last-child {
		border-start-end-radius: 3px;
		border-end-end-radius: 3px;
	}

	i {
		vertical-align: middle;
		margin: 0 -.25rem;
	}
}

a.tag-addon {
	text-decoration: none;
	cursor: pointer;
	transition: .3s color, .3s background;

	&:hover {
		background: rgba(0, 0, 0, 0.16);
		color: inherit;
	}
}

.tag-avatar {
	width: 1.5rem !important;
	height: 1.5rem !important;
	border-radius: 3px 0 0 3px;
	margin-block-start: 0px !important;
	margin-inline-end: 0.5rem !important;
	margin-block-end: 0px !important;
	margin-inline-start: -0.5rem !important;
	border-start-start-radius: 3px !important;
	border-start-end-radius: 0px !important;
	border-end-end-radius: 0px !important;
	border-end-start-radius: 3px !important;
}

.tag-blue {
	background-color: #467fcf !important;
	color: $white;
}

.tag-indigo {
	background-color: $indigo !important;
	color: $white;
}

.tag-purple {
	background-color: #867efc !important;
	color: $white;
}

.tag-pink {
	background-color: #ec82ef !important;
	color: $white;
}

.tag-red {
	background-color: #c21a1a !important;
	color: $white;
}

.tag-orange {
	background-color: $orange !important;
	color: $white;
}

.tag-yellow {
	background-color: #ecb403 !important;
	color: $white;
}

.tag-green {
	background-color: $green !important;
	color: $white;
}

.tag-teal {
	background-color: #2bcbba !important;
	color: $white;
}

.tag-cyan {
	background-color: #17a2b8 !important;
	color: $white;
}

.tag-white {
	background-color: $white !important;
	color: $white;
}

.tag-gray {
	background-color: #868e96 !important;
	color: $white;
}

.tag-gray-dark {
	background-color: $dark !important;
	color: $white;
}

.tag-cyan {
	background-color: $cyan !important;
	color: $white;
}

.tag-indigo {
	background-color: $indigo !important;
	color: $white;
}

.tag-primary {
	background-color: $primary-01 !important;
	color: $white;
}

.tag-secondary {
	background-color: #868e96 !important;
	color: $white;
}

.tag-success {
	background-color: $green !important;
	color: $white;
}

.tag-info {
	background-color: $info !important;
	color: $white;
}

.tag-warning {
	background-color: #ecb403 !important;
	color: $white;
}

.tag-danger {
	background-color: #c21a1a !important;
	color: $white;
}

.tag-light {
	background-color: #f8f9fa !important;
	color: $white;
}

.tag-dark {
	background-color: $dark !important;
	color: $white;
}

.tag-rounded {
	border-radius: 50px;

	.tag-avatar {
		border-radius: 50px;
	}
}

.tags {
	margin-bottom: -.5rem;
	font-size: 0;

	>.tag {
		margin-bottom: .5rem;

		&:not(:last-child) {
			margin-inline-end: .5rem !important;
		}
	}
}

.tag-avatar {
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 3px 0 0 3px;
	margin: 0 0.5rem 0 -0.5rem;
}

.tags>.tag:not(:last-child) {
	margin-inline-end: 0.5rem !important;
}