/* Start:: Breadcrumb Styles */

.page-header-breadcrumb {
  .breadcrumb-item+.breadcrumb-item::before {
    content: var(--bs-breadcrumb-divider, "/");
    font-family: "tabler-icons" !important;
  }
}

[dir="rtl"] {
  .page-header-breadcrumb {
    .breadcrumb-item+.breadcrumb-item::before {
      content: var(--bs-breadcrumb-divider, "/");
      font-family: "tabler-icons" !important;
    }
  }
}

.breadcrumb {
  .breadcrumb-item {
    a {
      color: $primary;
    }

    &.active {
      color: $default-text-color;
    }
  }
}

.breadcrumb-item+.breadcrumb-item::before {
  color: $text-muted;
}

.breadcrumb-example1 {
  .breadcrumb-item+.breadcrumb-item::before {
    color: $text-muted;
    content: var(--bs-breadcrumb-divider, "\ea65");
    font-family: "tabler-icons" !important;
  }
}

.breadcrumb-style1 {
  .breadcrumb-item+.breadcrumb-item::before {
    color: $text-muted;
    content: var(--bs-breadcrumb-divider, "\ea1c");
    font-family: "tabler-icons" !important;
  }
}

.breadcrumb-style2 {
  .breadcrumb-item+.breadcrumb-item::before {
    color: $text-muted;
    content: var(--bs-breadcrumb-divider, "\ea61");
    font-family: "tabler-icons" !important;
  }
}

.embedded-breadcrumb:before {
  opacity: 0.7;
}

[data-theme-mode="dark"] {
  .embedded-breadcrumb:before {
    filter: invert(1);
  }
}

[dir=rtl] .breadcrumb-example1 .breadcrumb-item+.breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, "\ea64");
}

[dir=rtl] .embedded-breadcrumb:before {
  transform: scaleX(-1);
  padding-inline-start: 0.5rem;
  padding-inline-end: 0 !important;
}

[dir=rtl] .breadcrumb-style2 .breadcrumb-item+.breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, "\ea60");
}

[dir=rtl] .breadcrumb-style1 .breadcrumb-item+.breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, "\ea1b");
}

/* End:: Breadcrumb Styles */